document.addEventListener('turbolinks:load', function () {
    var cards = document.querySelectorAll('.card');


    cards.forEach(function(card) {
      card.addEventListener('mouseover', function() {
        var lightClass = card.getAttribute('light');
        var lightHolder = document.querySelector('.lightHolder.' + lightClass);
        if (lightHolder) {
          lightHolder.style.opacity = '1';
        }
      });

      card.addEventListener('mouseout', function() {
        var lightClass = card.getAttribute('light');
        var lightHolder = document.querySelector('.lightHolder.' + lightClass);
        if (lightHolder) {
          lightHolder.style.opacity = '0.2';
        }
      });
    });
  });
